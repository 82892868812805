import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
  query {
    wordpressPage(title: { eq: "Reviews" }) {
      content
    }
  }
`

const ReviewsPage = ({ data }) => (
  <Layout>
    <SEO
      title="Reviews"
      keywords={[`conductor`, `musical supervisor`, `musicologist`]}
    />
    <h3 className="font-sans font-light mb-4 text-2xl sm:text-3xl underline">
      Reviews
    </h3>

    <div
      className="border-2 border-purple-500 bg-purple-200 p-3 mr-1 rounded-lg shadow-md paragraph-mb"
      dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }}
    />
  </Layout>
)

export default ReviewsPage
